import {
    makeAutoObservable
} from "mobx";
import axios from "axios";
import {
    useToast
} from "@chakra-ui/react";
import ConfigStore from "./ConfigStore";
import AppConst from "./Const";
import ModalStore from "./ModalStore";

class OgrenciStore {
    ogrenciler = [];
    isOnProcess = false;

    searchTimeout = null;

    currentPage = 1;
    rowCount = 10;

    totalRow = 0;

    constructor() {
        makeAutoObservable(this);
    }

    getToken(){
        return sessionStorage.getItem(AppConst.TOKEN_KEY);
    }

    ara(ad) {

        if (ad == '') return this.ogrenciCek();
        this.isOnProcess = true;
        let that = this

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(function () {
            axios.post(ConfigStore.server + (ConfigStore.port != 80 ? ':' + ConfigStore.port : '') + '/ogrenci/ara?rowCount=' + that.rowCount + '&page=' + that.currentPage, {
                adSoyad: ad
            }, {
                headers: {
                    Authorization: that.getToken()
                }
            }).then(function (response) {
                that.ogrenciler = (response.data.rows)
                that.totalRow = (response.data.count)
            }).catch(function (error) {
                console.log("hata")
            }).then(function () {
                that.isOnProcess = false;
            })
        }, 200);


    }

    backPage() {
        if (this.currentPage === 1) return false;
        this.currentPage -= 1;
        this.ogrenciCek()
    }
    nextPage() {

        if (this.currentPage === (this.totalRow === 0 ? 1 : Math.ceil(this.totalRow / this.rowCount))) return false;
        this.currentPage += 1;
        this.ogrenciCek()
    }

    setRowCount(c) {
        this.rowCount = c;
        this.ogrenciCek()
    }

    ogrenciEkle(ogrenciData, successCallback, errorCallback, completeCallback) {
        this.isOnProcess = true;
        let that = this
        axios.post(ConfigStore.server + (ConfigStore.port != 80 ? ':' + ConfigStore.port : '') + '/ogrenci/ekle', ogrenciData, {
            headers: {
                Authorization: this.getToken()
            }
        }).then(function (response) {
            successCallback()
        }).catch(function (error) {
            if (errorCallback) {
                errorCallback()
            }
        }).then(function () {
            if (completeCallback) {
                completeCallback()
            }
            that.isOnProcess = false;
        })
    }

    ogrenciCek() {
        this.isOnProcess = true;
        let that = this;
        axios.get(ConfigStore.server + (ConfigStore.port != 80 ? ':' + ConfigStore.port : '') + '/ogrenci?rowCount=' + this.rowCount + '&page=' + this.currentPage, {
            headers: {
                Authorization: this.getToken()
            }
        }).then(function (response) {
            that.ogrenciler = (response.data.rows)
            that.totalRow = (response.data.count)
        }, []).then(function () {
            that.isOnProcess = false;
        })
    }

    ogrenciSil(row, successCallback, errorCallback, completeCallback) {
        let that = this

        axios.delete(ConfigStore.server + (ConfigStore.port != 80 ? ':' + ConfigStore.port : '') + '/ogrenci/sil/' + row.original.id, {
            headers: {
                Authorization: this.getToken()
            }
        }).then(function (response) {
            if (response.data !== 0) {
                successCallback()
            } else {
                errorCallback();
            }
        }).catch(function (error) {
            if (errorCallback) {
                errorCallback()
            }
        }).then(function () {
            if (completeCallback) {
                completeCallback()
            }
        })
    }
}

export default new OgrenciStore();