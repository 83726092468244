import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AppConst from "./Const";
import App from "./App";
import Login from "./Login";
import {useEffect} from "react";
import ConfigStore from "./ConfigStore";
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Spinner} from "@chakra-ui/react";
import {observer} from "mobx-react";

function Router(){


    useEffect(function () {
        ConfigStore.getConfig()
    }, []);

  return (
<>
          {
              ConfigStore.isLoad !== true ?
                      (ConfigStore.isLoad === -1 ?
                              <Alert
                                  status='error'
                                  variant='subtle'
                                  flexDirection='column'
                                  alignItems='center'
                                  justifyContent='center'
                                  textAlign='center'
                                  height='200px'
                              >
                                  <AlertIcon boxSize='40px' mr={0} />
                                  <AlertTitle mt={4} mb={1} fontSize='lg'>
                                      HATA
                                  </AlertTitle>
                                  <AlertDescription maxWidth='sm'>Ayar dosyası okunamadı !</AlertDescription>
                              </Alert>
                              :
                              <Box h={"100vh"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                  <Spinner
                                      thickness='8px'
                                      speed='0.65s'
                                      emptyColor='gray.200'
                                      color='blue.500'
                                      size='xl'
                                  />
                              </Box>
                      )
                      :
              <>
              <BrowserRouter>
                  <Routes>
                  <Route path="/login"
                         element={
                             <PublicRoute>
                                 <Login/>
                             </PublicRoute>
                         }/>
                  <Route path="/"
                         element={
                             <ProtectedRoute>
                                 <App />
                             </ProtectedRoute>
                         } />

                  </Routes>
              </BrowserRouter>
              </>

          }
</>
  )
}

export default observer(Router);

const ProtectedRoute = ({ children, currentRole, permissions }) => {
  const token = sessionStorage.getItem(AppConst.TOKEN_KEY);

  if (token === null || token === undefined) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const PublicRoute = ({ children }) => {
  const token = sessionStorage.getItem(AppConst.TOKEN_KEY);

  if (token === null || token === undefined) {
    return children
  }

  return <Navigate to="/" replace />;
};