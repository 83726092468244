import OgrenciStore from "./OgrenciStore";
import ModalStore from "./ModalStore";
import ConfigStore from "./ConfigStore";

const AppStores = {
    OgrenciStore,
    ModalStore,
    ConfigStore
}

export default AppStores;