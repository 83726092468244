import {FormControl, FormLabel, Input, Box, Button, useToast, Select} from '@chakra-ui/react'
import {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import ModalStore from "./ModalStore";
import OgrenciStore from "./OgrenciStore";
import {SingleDatepicker} from "chakra-dayzed-datepicker";


const MONTH_NAMES = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık"
];
const DAY_NAMES = ["Pt", "Sl", "Ça", "Pe", "Cu", "Ct", "Pa"];
const DATE_FORMAT = "dd/MM/yyyy";

const OgrenciForm = function () {
    const [raporTarih, setRaporTarih] = useState(new Date());
    const [gelecekRaporTarih, setGelecekRaporTarih] = useState(new Date());
    const [ay, setAy] = useState(false)
    const [ogrenciKaydetIslem, setOgrenciKaydetIslem] = useState(false);
    const [adSoyad, setAdSoyad] = useState('');
    const toast = useToast()
    const isErrorName = adSoyad === ''
    const isErrorAy = ay === false || ay === ''


    useEffect(function () {
        let cp = new Date(raporTarih)
        setGelecekRaporTarih(new Date(cp.setMonth(cp.getMonth() + parseInt(ay))))
    }, [raporTarih, ay])


    const ogrenciKaydet = function () {
        let ogrenciData = {
            "adSoyad": adSoyad,
            "raporTarih": raporTarih.toLocaleString('sv',{timeZoneName:'short'}).slice(0, 10),
            "gelecekRaporTarih": gelecekRaporTarih.toLocaleString('sv',{timeZoneName:'short'}).slice(0, 10)
        };
        OgrenciStore.ogrenciEkle(ogrenciData, function () {
            toast({
                title: 'Başarılı',
                description: "Öğrenci Eklendi",
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: "top-left"
            })
            OgrenciStore.ogrenciCek()
        }, function () {
            toast({
                title: 'Hata',
                description: "Öğrenci Eklenirken hata oluştu",
                status: 'error',
                duration: 2000,
                isClosable: true, position: "top-left"
            })

        }, function () {
            ModalStore.closeModal()
        })
    }


    return <Box>
        <FormControl isRequired isInvalid={isErrorName}>
            <FormLabel>Ad Soyad</FormLabel>
            <Input placeholder='Ad Soyad ...'
                   value={adSoyad} onChange={(e) => setAdSoyad(e.currentTarget.value)}
            />
        </FormControl>
        <FormControl isRequired>
            <FormLabel>Rapor Tarih</FormLabel>
            <SingleDatepicker
                configs={
                    {
                        dateFormat: 'dd-MM-yyyy',
                        monthNames: MONTH_NAMES,
                        dayNames: DAY_NAMES
                    }
                }
                name="date-input"
                date={raporTarih}
                onDateChange={setRaporTarih}

            />
        </FormControl>
        <FormControl isRequired>
            <FormLabel>Gelecek Rapor </FormLabel>
            <Select placeholder='Seçiniz..' required={true} onChange={(e) => setAy(e.target.value)}
                    isInvalid={isErrorAy}>
                <option value='1'>1 Ay Sonra</option>
                <option value='2'>2 Ay Sonra</option>
                <option value='3'>3 Ay Sonra</option>
                <option value='4'>4 Ay Sonra</option>
                <option value='5'>5 Ay Sonra</option>
                <option value='6'>6 Ay Sonra</option>
                <option value='7'>7 Ay Sonra</option>
                <option value='8'>8 Ay Sonra</option>
                <option value='9'>9 Ay Sonra</option>
                <option value='10'>10 Ay Sonra</option>
                <option value='11'>11 Ay Sonra</option>
                <option value='12'>12 Ay Sonra</option>
            </Select>
        </FormControl>
        <Button
            mt={4}
            colorScheme='teal'
            type='submit'
            width={"full"}
            onClick={() => ogrenciKaydet()}
            isLoading={ogrenciKaydetIslem}
            disabled={isErrorName || isErrorAy}
        >
            Kaydet
        </Button>
    </Box>
}

export default observer(OgrenciForm);