import {
    Box,
    Flex,
    Avatar,
    HStack,
    Link,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack, Input, CircularProgress, Alert, AlertTitle, AlertDescription, Spinner,
} from '@chakra-ui/react';

import {HamburgerIcon, CloseIcon, AddIcon} from '@chakra-ui/icons';
import OgrenciTablo from "./OgrenciTablo";
import ConfigStore from "./ConfigStore";
import {useEffect} from "react";
import {observer} from "mobx-react";

;

const Links = [];

const NavLink = ({children}) => (
    <Link
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        href={'#'}>
        {children}
    </Link>
);

function AlertIcon(props: { boxSize: string, mr: number }) {
    return null;
}

function App() {
    const {isOpen, onOpen, onClose} = useDisclosure();

    let color = useColorModeValue('gray.100', 'gray.900');
    return (

        <>

                <>
                <Box bg={color} px={4}>
                    <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                        <IconButton
                            size={'md'}
                            icon={isOpen ? <CloseIcon/> : <HamburgerIcon/>}
                            aria-label={'Open Menu'}
                            display={{md: 'none'}}
                            onClick={isOpen ? onClose : onOpen}
                        />
                        <HStack spacing={8} alignItems={'center'}>
                            <Box> <Avatar name='logo' src='./logo.png' background={"transparent"}/>
                            </Box>
                            <HStack
                                as={'nav'}
                                spacing={4}
                                display={{base: 'none', md: 'flex'}}>
                                {Links.map((link) => (
                                    <NavLink key={link}>{link}</NavLink>
                                ))}
                            </HStack>

                        </HStack>
                        <Flex alignItems={'center'}>

                            <Menu>
                                <MenuButton
                                    as={Button}
                                    rounded={'full'}
                                    variant={'link'}
                                    cursor={'pointer'}
                                    minW={0}>
                                    <Avatar
                                        size={'sm'}
                                    />
                                </MenuButton>
                                <MenuList>
                                    <MenuItem>Hakkında</MenuItem>
                                    <MenuDivider/>
                                    <MenuItem
                                    onClick={() => {
                                        sessionStorage.clear();
                                        window.location.reload();
                                    }}
                                    >Çıkış Yap</MenuItem>
                                </MenuList>
                            </Menu>
                        </Flex>
                    </Flex>

                    {isOpen ? (
                        <Box pb={4} display={{md: 'none'}}>
                            <Stack as={'nav'} spacing={4}>
                                {Links.map((link) => (
                                    <NavLink key={link}>{link}</NavLink>
                                ))}
                            </Stack>
                        </Box>
                    ) : null}
                </Box>

                <Box p={4}>
                    <OgrenciTablo/>
                </Box>
            </>
            
        </>

    );
}

export default (App);
