import { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  useToast
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import AppConst from './Const';
import axios from 'axios';
import ConfigStore from "./ConfigStore";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = () => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState(""); 
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast()

  const handleShowClick = () => setShowPassword(!showPassword);

  async function login(){
    setIsLoading(true);
    if(user === "" || pass === "") {
      setIsLoading(false);
      toast({
        title: 'Uyarı',
        description: "Kullanıcı adı ve şifre boş olamaz",
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-left"
    })
      return;
    } 
    try{
      var res = await axios.post(ConfigStore.server + (ConfigStore.port != 80 ? ':' + ConfigStore.port : '')  + "/login", {
        user: user,
        pass: pass
      });
      toast({
        title: 'Başarılı',
        description: "Başarılı Giriş",
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: "top-left"
    })
      sessionStorage.setItem(AppConst.TOKEN_KEY, res.data.token);
      window.location.reload();
    }catch(e){
      console.log(e)
      toast({
        title: 'Hata',
        description: "Hatalı Giriş",
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-left"
    })
    }
    
    setIsLoading(false);

  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="teal.500" />
        <Heading color="teal.400">Öğrenci Rapor </Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input 
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                  type="text" placeholder="Kullanıcı Adı" />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    placeholder="Şifre"
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Gizle" :  "Göster"}
                    </Button>
                  </InputRightElement>
                </InputGroup>

              </FormControl>
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="teal"
                width="full"
                isLoading={isLoading}
                isDisable={isLoading}
                onClick={() => {
                  login();
                }}
              >
                Giriş Yap
              </Button>
            </Stack>
        </Box>
      </Stack>

    </Flex>
  );
};

export default Login;
