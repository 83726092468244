import {makeAutoObservable} from "mobx";

class ModalStore {
    isOpen = false;


    openModal(){
        this.isOpen = true;
    }

    closeModal(){
        this.isOpen = false;
    }

    toggleModal(){
        this.isOpen = !this.isOpen;
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new ModalStore();