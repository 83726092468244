import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Login';
import {ChakraProvider} from '@chakra-ui/react'
import {Provider} from "mobx-react";
import AppStores from "./AppStores";
import Router from './Router';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider {...AppStores}>
            <ChakraProvider>
                <Router />
            </ChakraProvider>
        </Provider>
    </React.StrictMode>
);

