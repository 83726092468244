import {DeleteIcon} from "@chakra-ui/icons";
import OgrenciStore from "./OgrenciStore";
import {IconButton, useToast} from "@chakra-ui/react";
import {useState} from "react";

const SilButon = function ({row}){
    const [isDisabled,setDisabled] = useState(false);
    const toast = useToast()

    return <IconButton
        variant='outline'
        colorScheme='teal'
        aria-label='Send email'
        icon={<DeleteIcon/>}
        disabled={isDisabled}
        onClick={function () {
            setDisabled(true);

            OgrenciStore.ogrenciSil(row,function () {
                OgrenciStore.ogrenciCek()

                toast({
                    title: 'Başarılı',
                    description: "Öğrenci Silindi",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    position:"top-left"
                })

            },function () {
                toast({
                    title: 'Hata',
                    description: "Öğrenci Silinirken hata oluştu",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                    position:"top-left"
                })
            },function () {
                setDisabled(false);
            })
        }}
    />
}

export default SilButon;