// This path is relative to root, e.g. http://localhost/config.json
import {makeAutoObservable} from "mobx";

class ConfigStore {
    port = 8000;
    server = "localhost";
    isLoad = false;

    constructor() {
        makeAutoObservable(this)
    }

    getConfig(){
        let that = this;
        fetch('./config.json',{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(function (response) {
           response.json().then(function (result){
               that.port = result.port;
               that.server = result.server;
               that.isLoad = true;
           }).catch(function (e){
               that.isLoad = -1
           })
        });
    }
}

export default new ConfigStore();