import {
    Badge,
    Box,
    Button,
    Flex,
    Heading,
    IconButton,
    Input, InputGroup, InputRightElement,
    Select, Spinner,
    Text,
    Tfoot,
    theme,
    useToast
} from "@chakra-ui/react";
import {
    AddIcon, ArrowBackIcon, ArrowForwardIcon,
    ArrowLeftIcon, ArrowRightIcon,
    ArrowUpIcon,
    DeleteIcon,
    SearchIcon, SmallCloseIcon,
    TriangleDownIcon,
    TriangleUpIcon
} from "@chakra-ui/icons";
import {useEffect, useMemo, useState} from "react";
import {Table, Thead, Tbody, Tr, Th, Td, chakra} from '@chakra-ui/react'
import {useTable} from 'react-table'
import {inject, observer} from "mobx-react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'

import OgrenciForm from "./OgrenciForm";
import ModalStore from "./ModalStore";
import OgrenciStore from "./OgrenciStore";
import SilButon from "./SilButon";


function OgrenciTablo() {
    const hesaplaKalanGun = function(date2){
        var date1 = new Date();
        var date2 = new Date(date2);
        var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
        return diffDays;
    }

    const renkSec = function (number) {
        if(number < 0){
            return theme.colors.red["700"];
        }
        else if (number < 20){
            return theme.colors.red["500"];
        }
        else if (number < 30){
            return theme.colors.red["400"];
        }
        else{
            return theme.colors.green["500"];
        }
    }

    const kolonlar = [
        {
            Header: '#',
            Cell: ({row}) => (<Text>{row.index + 1}</Text>),
            style: {
                textAlign: "left",
                padding: 0,
                width: "1%"
            }
        },
        {
            Header: 'Ad Soyad',
            accessor: 'adSoyad'
        },
        {
            Header: 'Rapor Tarih',
            accessor: 'raporTarih'
        },
        {
            Header: 'Gelecek Rapor Tarih',
            accessor: 'gelecekRaporTarih'
        },
        {
            Header: 'Kalan Gün',
            accessor: 'kalanGun',
            Cell: ({row}) => <Badge
                color={"white"}
                backgroundColor={renkSec(hesaplaKalanGun(row.values.gelecekRaporTarih))}>{hesaplaKalanGun(row.values.gelecekRaporTarih)}</Badge>
            },
        {
            Header: 'İşlemler',
            Cell: ({row}) => (<Flex justifyItems={"end"}><SilButon row={row}/></Flex>),
            style: {
                width: "1%",
                padding: "0",
                textAlign: "right"
            }
        },
    ];

    const columns = useMemo(
        () => kolonlar,
        []
    )

    useEffect(function () {
        OgrenciStore.ogrenciCek();
    }, [])

    let data = OgrenciStore.ogrenciler;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns, data})




    return (<>
            <Flex alignItems={"center"}>
                <Box>
                    <Heading as='h6' size={"md"}>Öğrenciler</Heading>
                </Box>
                <Box alignSelf={"left"} ml={"15px"}>
                    <Select onChange={(e) => OgrenciStore.setRowCount(e.target.value)}>
                        <option value={10} selected>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={-1}>TÜMÜNÜ GÖSTER</option>
                    </Select>
                </Box>
                <Flex marginLeft={"auto"} alignItems={"center"} gap={2}>
                    <Box>
                        <InputGroup>
                            <Input onChange={(e) => OgrenciStore.ara(e.target.value)} size={"sm"} variant={'filled'} placeHolder={"ara"} width={"full"} backgroundColor={"white"} borderColor={"gray"} />
                            { /*æ
                            <InputRightElement children={<IconButton  bgColor={"transparent"} size={"md"} aria-label={"temizle"} icon={<SmallCloseIcon  />} />} />
                        */ }
                        </InputGroup>

                    </Box>
                    <Button
                        variant={'solid'}
                        colorScheme={'teal'}
                        size={'sm'}
                        leftIcon={<AddIcon/>}
                        onClick={() => ModalStore.openModal()}
                    >
                        Ekle
                    </Button>
                </Flex>
            </Flex>
            <Table {...getTableProps()}>
                <Thead>
                    {headerGroups.map(headerGroup => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <Th
                                    {...column.getHeaderProps({
                                        style: column.style
                                    })}
                                >
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                {OgrenciStore.isOnProcess ? <Tbody w={"full"}>
                    <Tr>
                        <Td colSpan={6} align={"center"}>
                            <Box  w={"full"} textAlign={"center"}><Spinner size={"xl"} /></Box>
                        </Td>
                    </Tr>

                </Tbody> : <Tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <Td
                                            {...cell.getCellProps({
                                                style: cell.column.style
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>}
            </Table>
            <Box w={"full"}>
                <Flex justifyContent={"center"} alignItems={"center"} gap={"5px"}>
                    <IconButton aria-label='Search database' disabled={OgrenciStore.isOnProcess} icon={<ArrowLeftIcon />} />
                    <IconButton aria-label='Search database' disabled={OgrenciStore.isOnProcess} icon={<ArrowBackIcon />} onClick={() => OgrenciStore.backPage() }/>
                    <Text>{OgrenciStore.currentPage} / {OgrenciStore.totalRow === 0 ? 1 : Math.ceil(OgrenciStore.totalRow/OgrenciStore.rowCount)}</Text>
                    <IconButton aria-label='Search database' disabled={OgrenciStore.isOnProcess} icon={<ArrowForwardIcon />} onClick={() => OgrenciStore.nextPage()}/>
                    <IconButton aria-label='Search database' disabled={OgrenciStore.isOnProcess} icon={<ArrowRightIcon />} />
                </Flex>
            </Box>
            <Modal isOpen={ModalStore.isOpen} onClose={() => ModalStore.closeModal()}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Öğrenci Ekle</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <OgrenciForm/>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    )
}

export default observer(OgrenciTablo);